import type { FC } from 'react';
import React, { useContext } from 'react';
import { styled, css } from '@compiled/react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { N40, N20, N300 } from '@atlaskit/theme/colors';

import {
	ContentIcon,
	QuickActions,
	Subtitle,
	LastAction,
	StatusLozenge,
	Title,
	PageLink,
	PageCardContainer,
	Card,
} from '../Items';
import { PageCardsContext } from '../PageCardsContext';

const listCardStyle = css({
	minWidth: '0',
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
	display: 'grid',
	alignItems: 'center',
});

const gridTemplateColumnsMap = {
	FULL: '32px minmax(300px, 2fr) 1fr 1fr 128px',
	NO_LAST_ACTION: '32px minmax(300px, 2fr) 1fr 0fr 128px',
	NO_SUBTITLE: '32px minmax(300px, 2fr) 0fr 0fr 128px',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledCard = styled(Card)({
	boxShadow: 'none',
	border: `1px solid ${token('color.border', N40)}`,
	borderBottom: 'none',
	borderRadius: 0,
	'&:hover': {
		boxShadow: 'none',
		backgroundColor: token('color.background.accent.gray.subtlest', N20),
		'--secondary-text-color': token('color.text.subtle', N300),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	minWidth: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentIconContainer = styled.div({
	marginRight: token('space.200', '16px'),
	height: '16px',
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RoundedCornerContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	ListCard: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:first-of-type': {
			'border-top-left-radius': token('space.100', '8px'),
			'border-top-right-radius': token('space.100', '8px'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-empty-styled-expression -- Ignored via go/DSP-18766
const EmojiWrapper = styled.span({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleText = styled.div({
	marginRight: token('space.100', '8px'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const statusContainerStyles = xcss({
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtitleContainer = styled.div({
	overflow: 'hidden',
	marginRight: token('space.100', '8px'),
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LastActionContainer = styled.div({
	overflow: 'hidden',
	marginRight: token('space.100', '8px'),
});

export const CompactListView: FC = () => {
	// ⚠️ WARNING: Do you need to add conditional logic? Please refer to README#Views ⚠️
	const { width } = useContext(PageCardsContext);
	let breakPoint = 'FULL';
	if (width < 700) {
		breakPoint = 'NO_SUBTITLE';
	} else if (width < 900) {
		breakPoint = 'NO_LAST_ACTION';
	}

	return (
		<RoundedCornerContainer>
			<PageCardContainer StyledCard={StyledCard}>
				<PageLink compact />
				<div
					css={listCardStyle}
					style={{ gridTemplateColumns: gridTemplateColumnsMap[breakPoint] }}
					data-testid="page-card-compact-list-view"
				>
					<ContentIconContainer>
						<ContentIcon wrapper={EmojiWrapper} />
					</ContentIconContainer>
					<TitleContainer>
						<TitleText>
							<Title singleLine />
						</TitleText>
						<Box xcss={statusContainerStyles}>
							<StatusLozenge />
						</Box>
					</TitleContainer>
					<SubtitleContainer>
						<Subtitle />
					</SubtitleContainer>
					<LastActionContainer>
						<LastAction />
					</LastActionContainer>
					<QuickActions hidden compact />
				</div>
			</PageCardContainer>
		</RoundedCornerContainer>
	);
};
