import React from 'react';

import type { IconColor } from '@atlaskit/tokens/css-type-schema';
import { token } from '@atlaskit/tokens';
import GridIcon from '@atlaskit/icon/core/grid';
import Icon from '@atlaskit/icon';

const grid24Svg = () => (
	<svg width="24" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect
			width="24"
			height="24"
			transform="translate(0.5)"
			fillOpacity="0"
			fill={token('elevation.surface', '#FFFFFF')}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.5 4C5.39543 4 4.5 4.89543 4.5 6V18C4.5 19.1046 5.39543 20 6.5 20H18.5C19.6046 20 20.5 19.1046 20.5 18V6C20.5 4.89543 19.6046 4 18.5 4H6.5ZM18.5 6H13.5V11H18.5V6ZM18.5 13H13.5V18H18.5V13ZM11.5 11V6H6.5V11H11.5ZM6.5 13V18H11.5V13H6.5Z"
			fill="currentColor"
		/>
	</svg>
);
type Size = 'small' | 'medium';

type GridIconProps = {
	label: string;
	size?: Size;
	primaryColor?: IconColor;
	testId?: string;
};

export const Grid24Icon = (props: GridIconProps) => {
	const primaryColor = props.primaryColor || token('color.icon', '#44546F');
	const label = props.label || '';
	return (
		<GridIcon
			color={primaryColor}
			spacing="spacious"
			label={label}
			testId={props.testId}
			LEGACY_fallbackIcon={() => (
				<Icon glyph={grid24Svg} {...props} primaryColor={primaryColor} label={label} />
			)}
		/>
	);
};
